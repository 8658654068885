<template lang="pug">
Navbar
.bg-image
  .padding-top
    .container.d-none.d-md-block
      nav(aria-label='breadcrumb')
      ol.breadcrumb
        li.breadcrumb-item
          router-link.text-black.text-decoration-none(to='home') Home
        li.breadcrumb-item.active(aria-current='page') Message template

  HeadingLayout(:title="title", :desc="desc")
.mb-5
  template(v-for="message in messages")
    FeatureList(
      :title="message.title", :desc="message.desc", :image="message.image")
Footer
</template>

<script>
import Navbar from "../../components/TheNavbar";
import Footer from "../../components/TheFooter";
import HeadingLayout from "../../layout/HeadingLayout";
import FeatureList from "../../components/FeatureList";
export default {
  name: "MessageTemplate",
  components: { FeatureList, HeadingLayout, Footer, Navbar },
  data() {
    return {
      title: "Message template",
      desc: "Manage leads & clients, send auto-personalised content, track interest, and follow up like a sales professional. All can be done via your phone in seconds.",
      messages: [
        {
          image: "/images/features/message/Message_USP_Image_1.png",
          title: "Send Template Messages in bulk",
          desc: "Instantly send pre-saved introductions, follow ups, and details with our Bulk Message feature. Save the hassle of copy+paste, typing or worry about making mistakes. Spare your time on the manual message.",
        },
        {
          image: "/images/features/message/Message_USP_Image_2.png",
          title: "Your branding on the sales material",
          desc: "Phone call, SMS, WhatsApp, Email option in a quick glance. All the activities between and the customer are listed in a timeline format. Following up with your leads has never been easier\n\nYou can send template greetings and self-introductions with the template message function in a second. Say goodbye to copy +paste. ",
        },
        {
          image: "/images/features/message/Message_USP_Image_3.png",
          title: "Get notified on about your leads ",
          desc: "Get alerts when your leads open your PDF files and page links. Kloser will notify you to follow up if the file is opened and viewed by the new lead. Beside, you will get an auto generated reminder to the leads based on the last activity.",
        },
      ],
    };
  },
};
</script>
